* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

ul {
  list-style-type: none; }

a {
  color: inherit;
  text-decoration: none; }

ul,
ol,
div {
  margin: 0;
  padding: 0; }

html {
  font-family: "Noto Sans JP", sans-serif;
  color: #2f2e33;
  font-size: 10px; }
  @media screen and (max-width: 1200px) {
    html {
      min-width: 1200px; } }
  @media screen and (max-width: 767px) {
    html {
      min-width: 100%; } }

h1 {
  font-size: 74.78px; }
  @media screen and (max-width: 767px) {
    h1 {
      font-size: 35px; } }

h2 {
  font-size: 66.47px; }
  @media screen and (max-width: 767px) {
    h2 {
      font-size: 37.5px; } }

h4 {
  font-size: 33.24px; }
  @media screen and (max-width: 767px) {
    h4 {
      font-size: 18px; } }

h5 {
  font-size: 24.93px; }
  @media screen and (max-width: 767px) {
    h5 {
      font-size: 15px; } }

h6 {
  font-size: 16.62px; }
  @media screen and (max-width: 767px) {
    h6 {
      font-size: 15px; } }

.white {
  color: #fff; }

.white-bg {
  background: #fff; }

.black {
  color: #000; }

.black-bg {
  background: #000; }

.primary {
  color: #22386c; }

.primary-bg {
  background: #22386c; }

.overflow {
  overflow: hidden; }

.sp {
  display: none; }
  @media screen and (max-width: 767px) {
    .sp {
      display: block; } }

@media screen and (max-width: 767px) {
  .pc {
    display: none; } }

.header {
  width: 100%;
  padding: 30.88px 0 25.36px;
  text-align: center;
  background-color: #fff; }
  @media screen and (max-width: 767px) {
    .header {
      padding: 10.5px 0; } }
  .header__logo {
    width: 172px; }
    @media screen and (max-width: 767px) {
      .header__logo {
        width: 120px; } }

.banner {
  position: relative;
  height: 813.75px;
  width: 100%;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .banner {
      height: 600px;
      padding-bottom: 108.64px; } }
  .banner__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
    height: 100%;
    padding-top: 157.91px;
    background-image: url("/img/banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom; }
    @media screen and (max-width: 767px) {
      .banner__content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        padding-top: 33px; } }
  .banner__text {
    padding-top: 43.55px; }
    @media screen and (max-width: 767px) {
      .banner__text {
        padding-top: 15.4px; } }
  .banner__image {
    margin-right: 100px;
    height: 450px; }
    @media screen and (max-width: 767px) {
      .banner__image {
        width: 26px;
        height: auto;
        margin-right: 0; } }
  .banner__title {
    font-weight: 400;
    margin-bottom: 38px;
    line-height: 1.1em;
    font-family: "a-otf-ud-shin-go-pr6n", sans-serif;
    margin-left: -5px; }
    @media screen and (max-width: 767px) {
      .banner__title {
        margin-bottom: 0;
        margin-left: 20px;
        text-align: center; } }
  .banner__catchphrase {
    font-family: "kozuka-gothic-pr6n", sans-serif;
    font-size: 24.92px;
    margin-bottom: 3.54px;
    display: block; }
    @media screen and (max-width: 767px) {
      .banner__catchphrase {
        font-size: 16px;
        margin-bottom: 2px; } }
  .banner__logo {
    width: 318.55px; }
    @media screen and (max-width: 767px) {
      .banner__logo {
        width: 225px; } }
  @media screen and (max-width: 767px) {
    .banner__info {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      background-color: #fff;
      padding: 7.2px 0 19.6px; } }

.introduction {
  width: 100%;
  padding: 53.26px 0 68.65px;
  text-align: center;
  background-color: #22386c;
  color: #fff; }
  @media screen and (max-width: 767px) {
    .introduction {
      padding: 34.8876px 0 37.5804px; } }
  .introduction__text {
    font-size: 22.27px;
    font-weight: 500;
    line-height: 1.8em; }
    @media screen and (max-width: 767px) {
      .introduction__text {
        font-size: 16px; } }
  .introduction__paragraph:not(:last-child) {
    margin-bottom: 39.78px; }
    @media screen and (max-width: 767px) {
      .introduction__paragraph:not(:last-child) {
        margin-bottom: 31.1696px; } }

.features {
  padding: 111.62px 0 238.99px;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .features {
      padding: 22.3388px 6% 40.9068px; } }
  .features__container {
    width: 1429px;
    margin: 0 auto;
    max-width: 100%; }
  .features__main-title {
    padding-left: 194.96px; }
    @media screen and (max-width: 767px) {
      .features__main-title {
        padding-left: 0; } }
  .features__title {
    font-family: "proxima-nova", sans-serif;
    color: #22386c;
    line-height: 1.20009em; }
    @media screen and (max-width: 767px) {
      .features__title {
        line-height: 1em; } }
  .features__subtitle {
    font-weight: 700;
    display: block;
    font-size: 23.85px;
    margin-top: 11.77px; }
    @media screen and (max-width: 767px) {
      .features__subtitle {
        font-size: 13.5px;
        margin-top: 0; } }
  .features__list {
    margin-top: 118.67px; }
    @media screen and (max-width: 767px) {
      .features__list {
        margin-top: 34.32px; } }
  .features__item-content {
    padding: 0 68.72px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 70.94px; }
    @media screen and (max-width: 767px) {
      .features__item-content {
        padding: 0;
        margin-bottom: 23.6016px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
  .features__image {
    width: 311.59px;
    height: 311.59px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    font-family: "object-fit: cover; object-position: center;"; }
    @media screen and (max-width: 767px) {
      .features__image {
        width: 41.93vw;
        height: 41.93vw; } }
    .features__image:nth-child(-n + 2) {
      margin-bottom: 20.45px; }
      @media screen and (max-width: 767px) {
        .features__image:nth-child(-n + 2) {
          margin-bottom: 4.65%; } }
  .features__image-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 643.27px;
    height: 643.59px;
    margin-right: 57.52px;
    -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0;
    font-size: 0; }
    @media screen and (max-width: 767px) {
      .features__image-group {
        width: 100%;
        height: auto;
        margin-right: 0;
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
            -ms-flex-order: 2;
                order: 2; } }
    .features__image-group--full {
      width: 644px;
      height: 428.67px; }
      @media screen and (max-width: 767px) {
        .features__image-group--full {
          width: 100%;
          height: auto; } }
      .features__image-group--full .features__image {
        width: 100%;
        height: 100%; }
        @media screen and (max-width: 767px) {
          .features__image-group--full .features__image {
            margin-bottom: 0; } }
  .features__item-number {
    font-size: 83px;
    font-family: "proxima-nova", sans-serif;
    font-weight: 700;
    color: #22386c; }
    @media screen and (max-width: 767px) {
      .features__item-number {
        font-size: 41.5px; } }
  .features__item-title {
    line-height: 1.5em;
    margin-bottom: 19.13px; }
    @media screen and (max-width: 767px) {
      .features__item-title {
        margin-bottom: 15.3956px; } }
  @media screen and (max-width: 767px) {
    .features__item-text {
      margin-bottom: 17.578px;
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
          -ms-flex-order: 1;
              order: 1; } }
  .features__item-paragraph {
    font-size: 14.96px;
    line-height: 2em; }
    @media screen and (max-width: 767px) {
      .features__item-paragraph {
        font-size: 12px;
        line-height: 1.5em; } }
  .features__item:not(:last-child) {
    margin-bottom: 170.51px; }
    @media screen and (max-width: 767px) {
      .features__item:not(:last-child) {
        margin-bottom: 30px; } }
  .features__item:nth-child(even) .features__item-content {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
  .features__item:nth-child(even) .features__image-group {
    margin-right: 0; }
  .features__item:nth-child(even) .features__item-text {
    margin-right: 45.31px; }
    @media screen and (max-width: 767px) {
      .features__item:nth-child(even) .features__item-text {
        margin-right: 0; } }
  .features__message-list {
    padding: 0 68.72px; }
    @media screen and (max-width: 767px) {
      .features__message-list {
        padding: 0; } }
  .features__message-item {
    width: 100%;
    height: 225px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0 109.73px 0 62.24px;
    background-color: #eaebe9;
    border-radius: 20px;
    color: #4b4a51; }
    @media screen and (max-width: 767px) {
      .features__message-item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
            -ms-flex-align: start;
                align-items: flex-start;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        border-radius: 10px;
        padding: 14.2076px 19.36px 14.96px 17.6px;
        height: auto; } }
    .features__message-item:not(:last-child) {
      margin-bottom: 27px; }
      @media screen and (max-width: 767px) {
        .features__message-item:not(:last-child) {
          margin-bottom: 19.295px; } }
  .features__message-person {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 15px; }
    @media screen and (max-width: 767px) {
      .features__message-person {
        margin-bottom: 15.4px; } }
  .features__message-name {
    font-weight: 700;
    font-size: 27.6px;
    margin-right: 20px; }
    @media screen and (max-width: 767px) {
      .features__message-name {
        font-size: 17px;
        margin-right: 11.5px; } }
  .features__message-position {
    font-weight: 700;
    font-size: 17.63px; }
    @media screen and (max-width: 767px) {
      .features__message-position {
        font-size: 11px; } }
  .features__message-text {
    font-weight: 700;
    font-size: 27.6px;
    color: #22386c; }
    @media screen and (max-width: 767px) {
      .features__message-text {
        display: block;
        font-size: 17px;
        margin-bottom: 15.4px; } }
  .features__message-paragraph {
    width: 572.78px;
    font-size: 20.46px;
    line-height: 1.7em; }
    @media screen and (max-width: 767px) {
      .features__message-paragraph {
        width: 100%;
        font-size: 12px;
        line-height: 1.5em; } }
  .features__message-info {
    margin-right: 20px; }
    @media screen and (max-width: 767px) {
      .features__message-info {
        margin-right: 0; } }

.container {
  background-image: url("/img/features-bg.jpg");
  background-size: 100%;
  background-position: top -575px center; }
  @media screen and (max-width: 1595px) {
    .container {
      background-size: auto; } }
  @media screen and (max-width: 767px) {
    .container {
      background-image: url("/img/features-bg-sp.jpg");
      background-size: 100%;
      background-position: top 43px center; } }

.lineup {
  overflow: hidden; }
  .lineup__header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 36.28px; }
    @media screen and (max-width: 767px) {
      .lineup__header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-bottom: 17.6px; } }
  .lineup__title {
    font-family: "proxima-nova", sans-serif; }
  .lineup__header-text {
    mix-blend-mode: multiply;
    background-color: #22386c;
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    color: #fff;
    width: 50%;
    -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media screen and (max-width: 767px) {
      .lineup__header-text {
        mix-blend-mode: normal;
        width: 100%;
        padding: 32.3444px 0 38.0424px; } }
  .lineup__subtitle {
    font-weight: 700;
    font-size: 23.85px; }
    @media screen and (max-width: 767px) {
      .lineup__subtitle {
        font-size: 15px; } }
  .lineup__header-image-container {
    width: 100%;
    height: 314px; }
    @media screen and (max-width: 767px) {
      .lineup__header-image-container {
        height: 150px; } }
  .lineup__header-image {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    font-family: "object-fit: cover; object-position: center;"; }
  .lineup__list {
    width: 1429px;
    max-width: 100%;
    padding: 0 68.72px;
    margin: 0 auto; }
    @media screen and (max-width: 767px) {
      .lineup__list {
        padding: 0 6%; } }
  .lineup__item-image-container {
    width: 582px;
    max-width: 475px;
    -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start; }
    @media screen and (max-width: 767px) {
      .lineup__item-image-container {
        width: 100%;
        max-width: 100%;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        margin-bottom: 17.6px; } }
  .lineup__item-title {
    font-family: "proxima-nova", sans-serif; }
  @media screen and (max-width: 767px) {
    .lineup__item-image {
      width: 206.58px; } }
  .lineup__price-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 418.27px;
    font-size: 18px;
    line-height: 1.6em;
    padding: 9px 9px 12px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    margin-bottom: 33.24px; }
    @media screen and (max-width: 767px) {
      .lineup__price-container {
        width: 100%;
        padding: 8.8px 6.6px;
        margin-bottom: 20px;
        font-size: 11px; } }
    @media screen and (max-width: 370px) {
      .lineup__price-container {
        padding: 8.8px 0;
        font-size: 11px; } }
  .lineup__price-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%; }
  .lineup__item {
    width: 100%;
    border-radius: 30px;
    background-color: rgba(32, 164, 217, 0.75);
    padding: 51.91px 0 61px;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media screen and (max-width: 1200px) {
      .lineup__item {
        padding-left: 35px;
        padding-right: 35px;
        border-radius: 20px; } }
    @media screen and (max-width: 767px) {
      .lineup__item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        padding: 20.68px 22px;
        width: 94%;
        margin: 0 auto; } }
    .lineup__item:not(:last-child) {
      margin-bottom: 37.67px; }
      @media screen and (max-width: 767px) {
        .lineup__item:not(:last-child) {
          margin-bottom: 20px; } }
    .lineup__item--violet {
      background-color: rgba(124, 84, 225, 0.75); }
    .lineup__item--pink {
      background-color: rgba(220, 100, 136, 0.75); }
    .lineup__item--green {
      background-color: rgba(32, 196, 217, 0.75); }
    .lineup__item--gray {
      color: #2f2e33;
      background-color: rgba(234, 235, 233, 0.75); }
      .lineup__item--gray .lineup__item-image:not(:first-child) {
        margin-top: -260px; }
        @media screen and (max-width: 767px) {
          .lineup__item--gray .lineup__item-image:not(:first-child) {
            margin-top: -220px; } }
      @media screen and (max-width: 767px) {
        .lineup__item--gray .lineup__item-image-container {
          height: 250px;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
              -ms-flex-pack: center;
                  justify-content: center; } }
      .lineup__item--gray .lineup__price-container {
        border-color: #2f2e33; }
      @media screen and (max-width: 767px) {
        .lineup__item--gray .lineup__description {
          margin: 10.12px 0 11px; } }
      .lineup__item--gray .lineup__quantity span:first-child {
        display: inline-block;
        width: 145px; }
        @media screen and (max-width: 767px) {
          .lineup__item--gray .lineup__quantity span:first-child {
            width: 95px; } }
  .lineup__item-content {
    width: 543px; }
    @media screen and (max-width: 767px) {
      .lineup__item-content {
        width: 100%; } }
  .lineup__category {
    font-size: 19.94px;
    font-weight: 700;
    margin-bottom: 11px;
    display: block; }
    @media screen and (max-width: 767px) {
      .lineup__category {
        font-size: 12.465px;
        margin-bottom: 0; } }
  .lineup__description {
    margin: 28.39px 0 44px;
    font-size: 16.62px;
    line-height: 1.9em; }
    @media screen and (max-width: 767px) {
      .lineup__description {
        margin: 14.52px 0 28.6px;
        font-size: 10.385px;
        line-height: 1.5em; } }
  .lineup__quantity {
    font-weight: 700; }
  .lineup__link {
    width: 415.45px;
    height: 66.47px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    font-size: 19.94px;
    font-weight: 700;
    background-color: #2f2e33;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 22.71px 0;
    position: relative;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
    color: #fff; }
    @media screen and (max-width: 767px) {
      .lineup__link {
        width: 100%;
        height: 41.5px;
        padding: 7.92px 0;
        font-size: 12.465px; } }
    .lineup__link:hover {
      opacity: 0.75; }
    .lineup__link::before {
      content: '';
      position: absolute;
      top: 16.22px;
      left: 16.62px;
      width: 32.16px;
      height: 33.24px;
      background-image: url("/img/cart.png");
      background-size: 100%;
      background-repeat: no-repeat; }
      @media screen and (max-width: 767px) {
        .lineup__link::before {
          top: 9.81px;
          left: 11.225px;
          width: 20.1px;
          height: 20.775px; } }
    .lineup__link::after {
      content: '';
      position: absolute;
      top: 26px;
      right: 15px;
      width: 11px;
      height: 15px;
      background-image: url("/img/arrow-white.png");
      background-size: 100%;
      background-repeat: no-repeat; }
      @media screen and (max-width: 767px) {
        .lineup__link::after {
          width: 6.675px;
          height: 9.79px;
          top: 16.88px;
          right: 9.885px; } }
  .lineup__limited {
    width: 66.47px;
    height: 66.47px;
    color: #fff;
    font-size: 19.69px;
    font-weight: 700;
    background-color: #b22;
    border-radius: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media screen and (max-width: 767px) {
      .lineup__limited {
        width: 41.5px;
        height: 41.5px;
        font-size: 12.305px; } }
  .lineup__button-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 10px;
    width: 415.45px; }
    @media screen and (max-width: 767px) {
      .lineup__button-group {
        width: 100%; } }
  .lineup__button {
    width: calc(50% - 5px);
    font-size: 19.94px;
    font-weight: 700;
    background-color: #eaebe9;
    color: #2f2e33;
    text-align: center;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
    height: 66.47px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media screen and (max-width: 767px) {
      .lineup__button {
        font-size: 12.305px;
        height: 41.5px; } }
    .lineup__button:hover {
      opacity: 0.75; }

.about {
  overflow: hidden;
  margin: 97px 0 137px; }
  @media screen and (max-width: 767px) {
    .about {
      margin: 34.7512px 0 50px;
      padding: 0 6%; } }
  .about__list {
    margin-top: 55.83px; }
    @media screen and (max-width: 767px) {
      .about__list {
        margin-top: 21.8988px; } }
  .about__image-container {
    width: 45.42%;
    -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0;
    text-align: center;
    background-color: #fff; }
    @media screen and (max-width: 767px) {
      .about__image-container {
        width: 100%;
        height: 200px; } }
  .about__image {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    font-family: "object-fit: cover; object-position: center;";
    height: 100%;
    max-width: 100%; }
    .about__image--auto {
      width: auto; }
  .about__content {
    width: 100%;
    background-color: #22386c;
    color: #fff;
    padding: 24.5px 111px 26.26px 40.62px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media screen and (max-width: 767px) {
      .about__content {
        padding: 12.9448px 22px 14.6564px 17.6px; } }
  .about__item-title {
    line-height: 1.7em; }
    @media screen and (max-width: 767px) {
      .about__item-title {
        margin-bottom: 27.1744px; } }
  .about__text {
    font-size: 14.96px;
    line-height: 2em; }
    @media screen and (max-width: 767px) {
      .about__text {
        font-size: 12px;
        line-height: 1.5em; } }
  .about__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 1183.81px;
    height: 336.84px;
    margin: 0 auto; }
    @media screen and (max-width: 767px) {
      .about__item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        width: 100%;
        height: auto; } }
    .about__item:not(:last-child) {
      margin-bottom: 29.87px; }
      @media screen and (max-width: 767px) {
        .about__item:not(:last-child) {
          margin-bottom: 20px; } }

.safety {
  padding: 74.25px 0 80.66px;
  background: #f3f3f1;
  mix-blend-mode: multiply; }
  @media screen and (max-width: 767px) {
    .safety {
      padding: 45.298px 6% 50.3976px; } }
  .safety__main-title {
    text-align: center;
    margin-bottom: 80px;
    font-family: "proxima-nova", sans-serif; }
    @media screen and (max-width: 767px) {
      .safety__main-title {
        margin-bottom: 39.6px; } }
  .safety__subtitle {
    font-size: 19.94px;
    font-weight: 700;
    margin-top: 11.77px;
    display: block; }
    @media screen and (max-width: 767px) {
      .safety__subtitle {
        font-size: 15px;
        margin-top: 0; } }
  .safety__list {
    width: 993px;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media screen and (max-width: 767px) {
      .safety__list {
        width: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
  .safety__item {
    width: 451.74px; }
    @media screen and (max-width: 767px) {
      .safety__item {
        width: 94%;
        margin: 0 auto; } }
    .safety__item:not(:nth-last-child(-n + 2)) {
      margin-bottom: 61.41px; }
      @media screen and (max-width: 767px) {
        .safety__item:not(:nth-last-child(-n + 2)) {
          margin-bottom: 50px; } }
    @media screen and (max-width: 767px) {
      .safety__item:not(:last-child) {
        margin-bottom: 50px; } }
  .safety__header-container {
    height: 90.71px; }
    @media screen and (max-width: 767px) {
      .safety__header-container {
        height: auto;
        margin-bottom: 17.7716px; } }
  .safety__header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
  .safety__item-title {
    line-height: 1.7em;
    margin-left: 16px; }
    @media screen and (max-width: 767px) {
      .safety__item-title {
        margin-left: 15px; } }
  .safety__text {
    font-size: 13.29px;
    line-height: 2em; }
    @media screen and (max-width: 767px) {
      .safety__text {
        font-size: 12px;
        line-height: 1.5em; } }

.media {
  width: 1043px;
  margin: 139.88px auto 89px;
  max-width: 100%; }
  @media screen and (max-width: 767px) {
    .media {
      margin: 26.0348px 0 50px;
      padding: 0 6%; } }
  .media__header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 50.23px; }
    @media screen and (max-width: 767px) {
      .media__header {
        margin-bottom: 25.52px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
            -ms-flex-align: start;
                align-items: flex-start; } }
  @media screen and (max-width: 767px) {
    .media__main-title {
      margin-bottom: 25.5816px; } }
  .media__title {
    color: #22386c;
    font-family: "proxima-nova", sans-serif; }
  .media__subtitle {
    font-size: 23.85px;
    margin-top: 2.77px;
    display: block;
    font-weight: 700; }
    @media screen and (max-width: 767px) {
      .media__subtitle {
        font-size: 13.5px;
        margin-top: 0; } }
  .media__tagline {
    font-size: 16.62px; }
    @media screen and (max-width: 767px) {
      .media__tagline {
        font-size: 12px; } }
  .media__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media screen and (max-width: 767px) {
      .media__list {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
  .media__item {
    padding-bottom: 17.89px; }
    @media screen and (max-width: 767px) {
      .media__item {
        padding: 31.16px 3.96px 20px 0;
        width: 94%;
        margin: 0 auto; } }
    .media__item:nth-child(2) {
      padding: 0 28.67px;
      border-left: 1px solid #d5d6d2;
      border-right: 1px solid #d5d6d2; }
      @media screen and (max-width: 767px) {
        .media__item:nth-child(2) {
          padding: 31.16px 3.96px 20px 0;
          border: 0;
          border-top: 1px solid #d5d6d2;
          border-bottom: 1px solid #d5d6d2; } }
    @media screen and (max-width: 767px) {
      .media__item:first-child .media__logo {
        width: 243px; } }
    @media screen and (max-width: 767px) {
      .media__item:nth-child(2) .media__logo, .media__item:last-child .media__logo {
        width: 250px; } }
  .media__logo-container {
    height: 79.6px;
    margin-bottom: 21.42px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media screen and (max-width: 767px) {
      .media__logo-container {
        height: auto;
        margin-bottom: 22.1276px; } }
  .media__text {
    font-size: 14.96px;
    font-weight: 700;
    line-height: 1.7em;
    height: 94.35px;
    position: relative;
    z-index: 1; }
    @media screen and (max-width: 767px) {
      .media__text {
        font-size: 16.25px;
        height: auto; } }
  .media__date {
    text-align: right;
    display: block;
    font-family: "proxima-nova", sans-serif;
    font-size: 13.29px; }
    @media screen and (max-width: 767px) {
      .media__date {
        font-size: 14.445px;
        margin-top: 19.074px; } }
  .media__logo-list {
    margin-top: 39.18px;
    width: 100%;
    padding: 38.1px 76.59px 37.93px;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    border-radius: 30px;
    background-color: #fff;
    border: 1px solid #f3f3f1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media screen and (max-width: 767px) {
      .media__logo-list {
        margin-top: 13.376px;
        padding: 6.5% 4.45% 5.89% 4.92%;
        border-radius: 20px;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between; } }
  @media screen and (max-width: 767px) {
    .media__logo-image {
      width: 100%; } }
  .media__logo-item {
    margin-right: 50.53px; }
    @media screen and (max-width: 767px) {
      .media__logo-item {
        margin-right: 0;
        text-align: center; } }
    .media__logo-item:nth-child(3n), .media__logo-item:last-child {
      margin-right: 0; }
    .media__logo-item:not(:nth-last-child(-n + 2)) {
      margin-bottom: 32.37px; }
      @media screen and (max-width: 767px) {
        .media__logo-item:not(:nth-last-child(-n + 2)) {
          margin-bottom: 20.196px; } }
    @media screen and (max-width: 767px) {
      .media__logo-item:first-child {
        width: 45.74%; } }
    @media screen and (max-width: 767px) {
      .media__logo-item:nth-child(2) {
        width: 44.06%; } }
    @media screen and (max-width: 767px) {
      .media__logo-item:nth-child(3) {
        width: 100%; } }
    @media screen and (max-width: 767px) {
      .media__logo-item:nth-child(3) .media__logo-image {
        width: 44.06%; } }
    @media screen and (max-width: 767px) {
      .media__logo-item:nth-child(4) {
        width: 40.06%; } }
    @media screen and (max-width: 767px) {
      .media__logo-item:last-child {
        width: 28.84%;
        margin-right: 7%; } }

.faq__header {
  background-color: #22386c;
  color: #fff;
  text-align: center;
  padding: 28.77px 0 39.91px; }
  @media screen and (max-width: 767px) {
    .faq__header {
      padding: 32.3444px 0 38.0424px; } }

.faq__subtitle {
  font-weight: 700;
  font-size: 23.85px;
  margin-top: 6.77px;
  display: block; }
  @media screen and (max-width: 767px) {
    .faq__subtitle {
      font-size: 15px;
      margin-top: 0; } }

.faq__content {
  margin: 52.52px auto 99.71px;
  width: 997px; }
  @media screen and (max-width: 767px) {
    .faq__content {
      margin: 20px 0 50px;
      padding: 0 6%;
      width: 100%; } }

.faq__block-title {
  font-size: 26.59px;
  cursor: pointer;
  position: relative; }
  @media screen and (max-width: 767px) {
    .faq__block-title {
      font-size: 15px; } }
  .faq__block-title::after {
    content: '';
    position: absolute;
    top: 15px;
    right: 0;
    width: 29px;
    height: 15px;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
    background-image: url("/img/arrow-down.png");
    background-size: 100%;
    background-repeat: no-repeat; }
    @media screen and (max-width: 767px) {
      .faq__block-title::after {
        top: 7.86px;
        right: 0;
        width: 13.465px;
        height: 8.59px; } }

.faq__list {
  overflow: hidden;
  max-height: 0;
  margin-top: 20px;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease; }
  @media screen and (max-width: 767px) {
    .faq__list {
      margin-top: 9.24px;
      -webkit-transition: 0.2s ease;
      -o-transition: 0.2s ease;
      transition: 0.2s ease; } }

.faq__question {
  position: relative;
  width: 100%;
  background-color: #c1ccff;
  padding: 9.74px 31.84px 9.74px 16.59px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 10px;
  margin-bottom: 15.89px;
  cursor: pointer; }
  @media screen and (max-width: 767px) {
    .faq__question {
      padding: 5.6144px 17.6px 5.6144px 7.04px;
      border-radius: 6px;
      margin-bottom: 6.5692px; } }
  .faq__question::before {
    content: '';
    position: absolute;
    right: 21.6px;
    top: 32px;
    width: 15px;
    height: 2px;
    border-radius: 4px;
    background-color: #fff; }
    @media screen and (max-width: 767px) {
      .faq__question::before {
        right: 10px;
        width: 7.5px;
        top: 50%;
        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%);
        height: 1px; } }
  .faq__question::after {
    content: '';
    position: absolute;
    right: 27.9px;
    top: 25.76px;
    width: 2px;
    height: 15px;
    border-radius: 4px;
    background-color: #fff; }
    @media screen and (max-width: 767px) {
      .faq__question::after {
        right: 13.325px;
        height: 7.5px;
        top: 50%;
        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%);
        width: 1px; } }

.faq__letter {
  font-family: "proxima-nova", sans-serif;
  font-weight: 700;
  font-size: 39px;
  margin-right: 16px;
  color: #22386c; }
  @media screen and (max-width: 767px) {
    .faq__letter {
      font-size: 24px;
      margin-right: 6.0676px; } }
  .faq__letter--a {
    color: #ba2729; }

.faq__question-text {
  font-weight: 700;
  font-size: 19.94px; }
  @media screen and (max-width: 767px) {
    .faq__question-text {
      font-size: 14px; } }

.faq__answer-container {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease; }
  @media screen and (max-width: 767px) {
    .faq__answer-container {
      -webkit-transition: 0.2s ease;
      -o-transition: 0.2s ease;
      transition: 0.2s ease; } }

.faq__answer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 0 31.84px 0 16.59px;
  margin-bottom: 21.84px; }
  @media screen and (max-width: 767px) {
    .faq__answer {
      padding: 0 0 0 8.4348px;
      margin-bottom: 11.572px; } }

.faq__answer-text {
  font-size: 14.96px;
  margin-top: 12px; }
  @media screen and (max-width: 767px) {
    .faq__answer-text {
      font-size: 14px;
      margin-top: 3px; } }

.faq__item:not(:last-child) {
  margin-bottom: 16.95; }

.faq__item:last-child {
  margin-bottom: 16.65px; }

.faq__item--active .faq__question::after {
  opacity: 0; }

.faq__block {
  border: 2px solid #d5d6d2;
  border-radius: 10px;
  padding: 20px 41px 0;
  position: relative;
  background-color: #fff; }
  @media screen and (max-width: 767px) {
    .faq__block {
      padding: 8.8px 8.8px 0;
      border-radius: 7px;
      border: 1px solid #d5d6d2; } }
  .faq__block:not(:last-child) {
    margin-bottom: 23.6px; }
    @media screen and (max-width: 767px) {
      .faq__block:not(:last-child) {
        margin-bottom: 10px; } }
  .faq__block--active .faq__block-title::after {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg); }

.faq__link {
  color: #22386c; }
  .faq__link:hover {
    text-decoration: underline; }

.faq__title {
  font-family: "proxima-nova", sans-serif; }

.product__content {
  margin: 49.46px 0 85.45px; }
  @media screen and (max-width: 767px) {
    .product__content {
      margin: 20px 0 47.295px;
      padding: 0 6%; } }

.product__main {
  width: 506px;
  margin: 0 auto 49.85px; }
  @media screen and (max-width: 767px) {
    .product__main {
      width: 100%;
      margin-bottom: 30px; } }

.product__main-image {
  position: relative;
  width: 100%;
  height: 300px;
  background-color: #eaebe9;
  border-radius: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media screen and (max-width: 767px) {
    .product__main-image {
      height: 192.69px;
      border-radius: 10px; } }

.product__limited {
  width: 66.47px;
  height: 66.47px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 19.69px;
  position: absolute;
  top: 8.71px;
  left: 9.47px;
  background-color: #b22;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media screen and (max-width: 767px) {
    .product__limited {
      width: 42.695px;
      height: 42.695px;
      font-size: 12.645px;
      top: 5.595px;
      left: 5.63px; } }

@media screen and (max-width: 767px) {
  .product__single-image {
    height: 171.56px; } }

.product__single-image:not(:last-child) {
  margin-right: 64.25px; }
  @media screen and (max-width: 767px) {
    .product__single-image:not(:last-child) {
      margin-right: 41.3px; } }

.product__info {
  margin-top: 10.33px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .product__info {
      margin-top: 17.9432px; } }

.product__subtitle {
  display: block;
  font-size: 16px;
  font-weight: 700; }
  @media screen and (max-width: 767px) {
    .product__subtitle {
      font-size: 10.5px; } }

.product__price {
  font-size: 16px;
  margin: 1.81px 0 13.8px;
  display: block; }
  @media screen and (max-width: 767px) {
    .product__price {
      font-size: 13px;
      margin: 6.6px 0 0; } }

.product__button {
  width: 299.13px;
  height: 66.47px;
  font-weight: 700;
  font-size: 16.62px;
  margin: 0 auto;
  background-color: #2f2e33;
  color: #fff;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media screen and (max-width: 767px) {
    .product__button {
      margin-top: 10px;
      height: 41.5px;
      font-size: 12.47px; } }
  @media screen and (max-width: 767px) {
    .product__button {
      width: 100%; } }
  .product__button:hover {
    opacity: 0.75; }

.product__button-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 10px auto 0;
  width: 299.13px; }
  @media screen and (max-width: 767px) {
    .product__button-group {
      width: 100%; } }

.product__link {
  width: calc(50% - 5px);
  padding: 8px 0;
  font-size: 19.94px;
  font-weight: 700;
  background-color: #eaebe9;
  color: #2f2e33;
  text-align: center;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  height: 66.47px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media screen and (max-width: 767px) {
    .product__link {
      font-size: 12.305px;
      height: 41.5px; } }
  .product__link:hover {
    opacity: 0.75; }

.product__cart {
  width: 25.73px;
  margin-right: 18px; }
  @media screen and (max-width: 767px) {
    .product__cart {
      width: 20.1px;
      height: 20.78px; } }

.product__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media screen and (max-width: 767px) {
    .product__list {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; } }

.product__item {
  width: 299.13px; }
  @media screen and (max-width: 767px) {
    .product__item {
      width: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  .product__item:not(:last-child) {
    margin-right: 49.85px; }
    @media screen and (max-width: 767px) {
      .product__item:not(:last-child) {
        margin-right: 0;
        margin-bottom: 30px; } }
  @media screen and (max-width: 767px) {
    .product__item .product__info {
      margin-top: 0;
      text-align: left;
      width: calc(100% - 137.6px); } }

.product__image-container {
  width: 100%;
  height: 300px;
  border-radius: 20px;
  background-color: #eaebe9;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media screen and (max-width: 767px) {
    .product__image-container {
      width: 120px;
      height: 120px;
      border-radius: 10px;
      margin-right: 17.6px;
      -webkit-flex-shrink: 0;
          -ms-flex-negative: 0;
              flex-shrink: 0; } }

@media screen and (max-width: 767px) {
  .product__image {
    height: 107px; } }

.footer {
  background-color: #22386c;
  color: #fff;
  padding: 71.88px 0 66.66px; }
  @media screen and (max-width: 767px) {
    .footer {
      padding: 50.38px 6% 48.4px;
      mix-blend-mode: multiply; } }
  .footer__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    width: 997px;
    margin: 0 auto; }
    @media screen and (max-width: 767px) {
      .footer__content {
        width: 100%; } }
  .footer__logo {
    width: 280px; }
    @media screen and (max-width: 767px) {
      .footer__logo {
        width: 165.91px; } }
  .footer__copyright {
    font-size: 9.97px;
    margin-top: 11.67px;
    display: block; }
    @media screen and (max-width: 767px) {
      .footer__copyright {
        font-size: 6.23px;
        margin-top: 5px; } }
  .footer__top {
    cursor: pointer;
    width: 58.16px;
    height: 58.16px;
    position: fixed;
    right: 62.57px;
    bottom: 72.57px;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
    -webkit-transform: translateY(200px);
        -ms-transform: translateY(200px);
            transform: translateY(200px);
    background-image: url("/img/back-top.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    @media screen and (max-width: 767px) {
      .footer__top {
        width: 30px;
        height: 30px;
        bottom: 52.9804px;
        right: 22px; } }
    .footer__top:hover {
      opacity: 0.75; }
    .footer__top--active {
      -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
              transform: translateY(0); }
  .footer__address {
    font-size: 14px; }
    @media screen and (max-width: 767px) {
      .footer__address {
        font-size: 12px;
        margin-top: 10.56px; } }
